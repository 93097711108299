import React from 'react'
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import Layout from "../components/layout/layout"
import BlogPostPreviewItem from "../components/blogPostPreviewItem"
import TitleHeader from "../components/layout/TitleHeader"
import GridContainer from "../components/layout/GridContainer"
import { ContentContainer } from "../components/layout/ContentLayoutComponents"

const IndexPage = ({ data }) => {
  const title = "Rezepte"
  return(
    <Layout>
      <SEO title={title} />
      <Helmet title={title} />

      <ContentContainer>
        <TitleHeader title={title}/>
        <GridContainer>
            { 
              data.allMarkdownRemark.edges.map(({node}) => (
                  <BlogPostPreviewItem item={node}/>
              ))
            }
          </GridContainer>
      </ContentContainer>
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {fileAbsolutePath: {regex: "/.*(recipes).*/"}, fields: {draft: {eq: false}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          date
          titleImageName
          category
        }
        excerpt
      }
    }
  }
}
`


